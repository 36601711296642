<template>
  <div class="bgimg">
    <div class="middle">
      <router-link
        slot="brand"
        class="navbar-brand pl-3"
        to="/"
        v-if="logo_link"
      >
        <img src="../assets/img/under_contructions.png" alt="Image" />
      </router-link>
      <h1>COMING SOON</h1>
      <hr />
      <p id="demo" style="font-size: 30px"></p>
    </div>
    <div class="bottomleft">
      <p></p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'under_contructions',
  components: {},
  data() {
    return {
      logo_link: null,
      loading: true,
      errored: false,
    };
  },
  mounted: function () {
    //Logo
    this.$store
      .dispatch('headerModule/fetchLogo')
      .then(() => {
        this.logo_link = this.$store.getters['headerModule/getLogo'];
      })
      .catch((error) => {
        console.log(error);
        this.errored = true;
      })
      .finally(() => (this.loading = false));

    var countDownDate = new Date('Mar 5, 2022 15:37:25').getTime();

    // Update the count down every 1 second
    var countdownfunction = setInterval(function () {
      // Get todays date and time
      var now = new Date().getTime();

      // Find the distance between now an the count down date
      var distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
      );
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);

      // Output the result in an element with id="demo"
      document.getElementById('demo').innerHTML =
        days + 'd ' + hours + 'h ' + minutes + 'm ' + seconds + 's ';

      // If the count down is over, write some text
      if (distance < 0) {
        clearInterval(countdownfunction);
        document.getElementById('demo').innerHTML = 'EXPIRED';
      }
    }, 1000);
  },
};
</script>
<style type="text/css">
.bgimg {
  /* background-image: url('../assets/img/under_contructions.png'); */
  background-color: black;
  margin: 0;
  height: 100vh;
  /* background-position: center;
  background-size: cover; */
  position: relative;
  color: white;
  font-family: 'Courier New', Courier, monospace;
  font-size: 25px;
}

.topleft {
  position: absolute;
  top: 0;
  left: 16px;
}

.bottomleft {
  position: absolute;
  bottom: 0;
  left: 16px;
}

.middle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

hr {
  margin: auto;
  width: 40%;
}

#global_logo {
  position: relative;
  max-width: 150px;
  margin: 20px;
}
</style>
